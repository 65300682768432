<template>
  <div>

    <el-row
      type="flex"
      justify="end"
    >
      <div
        @click="collapse = !collapse"
        class="mr-2"
      >
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Filtrar Relato"
        >
          <i
            slot="reference"
            :class="collapse? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          ></i></el-popover>
      </div>
      <div @click="irParaCadastrarOuvidoria()">
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Cadastrar Relato"
        >
          <i
            slot="reference"
            class="glyphicon glyphicon-plus-sign"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <br>
    <vuestic-widget v-show="collapse">
      <div class="container">
        <div class="row">
          <div class="col">
            <fieldset>
              <vuestic-radio-button
                option=""
                v-model="situacao"
                label="Todos"
              />
            </fieldset>
          </div>
          <div class="col">
            <vuestic-radio-button
              option="1"
              v-model="situacao"
              label="Pendente"
            />
          </div>
          <div class="col">
            <vuestic-radio-button
              option="2"
              v-model="situacao"
              label="Cancelados"
            />
          </div>
          <div class="col">
            <vuestic-radio-button
              option="3"
              v-model="situacao"
              label="Tratados"
            />
          </div>

        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  id="simple-input-setor"
                  v-model="pesquisaAvancada.setor"
                  required
                />
                <label
                  for="simple-input-setor"
                  class="control-label"
                >Setor</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  id="simple-input-name"
                  v-model="pesquisaAvancada.nome"
                  required
                />
                <label
                  for="simple-input-name"
                  class="control-label"
                >Nome</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  id="simple-input-titele"
                  v-model="pesquisaAvancada.titulo"
                  required
                />
                <label
                  for="simple-input-titele"
                  class="control-label"
                >Titulo</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  id="simple-input-setor"
                  v-model="pesquisaAvancada.descricao"
                  required
                />
                <label
                  for="simple-input-setor"
                  class="control-label"
                >Descrição</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <vuestic-date-picker
                id="date-picker-range"
                :config="{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                    }"
                v-model="pesquisaAvancada.dataCadastro"
              />
              <i
                v-if="pesquisaAvancada.dataCadastro"
                class="fa fa-times icon-right input-icon pointer"
                @click="clearDate()"
              >
              </i>
              <label
                class="control-label"
                for="date-picker-basic"
              >
                Data de cadastro
              </label>
            </div>
          </div>
        </div>
      </div>

      <!---->

      <form>
        <el-row
          type="flex"
          class="row-bg"
          justify="end"
        >
          <el-button
            class="ml-3"
            type="primary"
            icon="el-icon-search"
            @click="getOuvidoria()"
          >
            Filtrar
          </el-button>
          <exportar-ouvidoria
            class="ml-3"
            :urlExportacao="urlExportacao"
						:idEmpresa="idEmpresa"
          />
          <el-button
            class="ml-3"
            type="danger"
            icon="el-icon-close"
            @click="limparPesquisa()"
          >
            Limpar
          </el-button>
        </el-row>
      </form>

      <!---->

    </vuestic-widget>

    <vuestic-widget>

      <data-tables-server
        v-loading="loading"
        :pagination-props="{ pageSizes: [10, 20, 50, 100] }"
        :total="count"
        style="width: 100%;"
        @query-change="getOuvidoria"
        :data="ouvidorias"
        @row-dblclick="openModal"
      >
        <el-table-column
          label="Status"
          prop="situacao"
          width="100"
        >
          <template
            slot-scope="row"
            style="width: 100%;"
          >
            <div
              :class="status.cor[row.row.situacao]"
              class="badge"
            >{{status.nome[row.row.situacao]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="Setor"
          sortable="custom"
          prop="setor_nome"
        />
        <el-table-column
          label="Nome"
          sortable="custom"
          prop="funcionario"
        />
        <el-table-column
          label="Titulo"
          sortable="custom"
          prop="titulo"
        />
        <el-table-column
          label="Descrição"
          sortable="custom"
          prop="queixa"
        />
        <el-table-column
          label="Prazo"
          prop="prazoFormatado"
        />
      </data-tables-server>
    </vuestic-widget>
    <editar-ouvidoria
      :ouvidoria="ouvidoria"
      :setores="setores"
			v-on:success="getOuvidoria"
      ref="modalEditar"
    />

  </div>
</template>

<script>
import moment from 'moment';
import exportarOuvidoria from '@/components/my-components/export/ouvidoria.vue';
import editarOuvidoria from '../form/editar/modal/ouvidoria.vue';

export default {
  components: { exportarOuvidoria, editarOuvidoria },
  data() {
    return {
      setores: [],
      collapse: false,
      situacao: '',
      pesquisaAvancada: {
        setor: '', nome: '', titulo: '', descricao: '', dataCadastro: '',
      },
      status: {
        nome: {
          1: 'Pendente',
          2: 'Cancelado',
          3: 'Tratado',
        },
        cor: {
          1: 'badge-danger',
          2: 'badge-dark',
          3: 'badge-success',
        },
      },
      loading: true,
      count: 0,
      ouvidorias: [],
      idEmpresa: localStorage.empresaSelecionada,
      url: '',
      urlExportacao: '',
      ouvidoria: {},
      nomeEmpresa: '',
    };
  },
  created() {
    this.getOuvidoria();
    this.getEmpresa();
  },
  methods: {
    openModal(val) {
      this.ouvidoria = val;
      this.$refs.modalEditar.open();
    },
    async getOuvidoria(pagina) {
      await this.formarUrl(pagina);
      this.loading = true;
      this.$axios.get(`api/ouvidoria-por-empresa/${this.idEmpresa}/${this.url}`).then((res) => {
        this.count = res.data.count;
        this.loading = false;
        this.ouvidorias = this.adicionarCampoDePrazoFormatado(res.data.results);
      });
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.idEmpresa}/`).then((res) => {
        this.getSetores(res.data.areas);
        this.nomeEmpresa = res.data.nome;
        this.formarUrl();
      });
    },
    getSetores(areas) {
      this.$axios.get('/api/area-list/').then((res) => {
        this.setores = res.data.filter(area => areas.findIndex(el => el === area.id) !== -1);
      });
    },
    adicionarCampoDePrazoFormatado(dado) {
      dado.map((c) => {
        const novaOuvidoria = c;
        novaOuvidoria.prazoFormatado = c.prazo ? moment(c.prazo).format('DD/MM/YYYY') : '';
        return novaOuvidoria;
      });
      return dado;
    },

    endPointPesquisa(paginaAtual) {
      const { pesquisaAvancada } = this;
      let pesquisa = '';
      let pagina = '';
      const orderBy = paginaAtual ? this.sort(paginaAtual.sort) : '';

      if (paginaAtual) {
        pagina = `page=${paginaAtual.page}&paginas=${paginaAtual.pageSize}&`;
      }

      if (this.situacao) {
        pesquisa += `situacao=${this.situacao}&`;
      }
      if (pesquisaAvancada.dataCadastro) {
        const dataDividida = pesquisaAvancada.dataCadastro.replace(/\//g, '%2F').split(' to ');
        pesquisa = `tipo=cadastro&min_date=${dataDividida[0]}&max_date=${dataDividida[1]}&`;
      }
      if (pesquisaAvancada.titulo) {
        pesquisa += `titulo=${pesquisaAvancada.titulo}&`;
      }
      if (pesquisaAvancada.setor) {
        pesquisa += `setor=${pesquisaAvancada.setor}&`;
      }
      if (pesquisaAvancada.nome) {
        pesquisa += `nome=${pesquisaAvancada.nome}&`;
      }
      if (pesquisaAvancada.descricao) {
        pesquisa += `queixa=${pesquisaAvancada.descricao}&`;
      }
      return `?${pesquisa}${pagina}${orderBy}`;
    },

    sort(orderBy) {
      let newOrder = '';
      if (orderBy.order === 'descending') {
        newOrder = '-';
      }
      if (orderBy.prop === 'setor_nome') {
        newOrder += 'setor__nome';
      } else {
        newOrder += orderBy.prop;
      }
      return `ordering=${newOrder}&`;
    },
    limparPesquisa() {
      this.pesquisaAvancada = {
        setor: '', nome: '', titulo: '', chamado: '', dataCadastro: '',
      };
      this.url = '';
      this.situacao = '';
      this.getOuvidoria();
    },
    formarUrl(pagina) {
      this.urlExportacao = `/api/ouvidoria/${this.endPointPesquisa(pagina)}empresa=${this.nomeEmpresa}`;
      this.url = this.endPointPesquisa(pagina);
    },
    clearDate() {
      this.pesquisaAvancada.dataCadastro = '';
    },
    irParaCadastrarOuvidoria() {
      const nameUrlRoot = this.$router.history.current.matched[0].name;
      if (nameUrlRoot === 'User') {
        this.$router.push({ name: 'cadastrar-ouvidoria-user' });
      } else {
        this.$router.push({ name: 'cadastrar-ouvidoria-supervisor' });
      }
    },
  },
  watch: {
    situacao() {
      this.getOuvidoria();
    },
  },

};
</script>

<style>
.form-group .input-icon {
	position: absolute;
	top: 0.8rem;
	color: #b3b3b3;
	right: 4px;
}
</style>
