<template>
  <downloadexcel
    :fetch="getRelatos"
    :fields="json_fields"
    name="Ouvidoria.xls"
    type="xls"
  >
    <el-button
      type="info"
      icon="el-icon-download"
    >Exportar</el-button>
  </downloadexcel>
</template>

<script>
/* eslint-disable */
import downloadexcel from 'vue-json-excel';
import moment from 'moment'

export default {
  name: 'App',
  components: {
    downloadexcel
  },
  props: {
    relatos: {
      type: Array
    },
    urlExportacao: {
      type: String
    },
    idEmpresa: {}
  },
  data () {
    return {
      situacao: {
        nome: {
          1: 'Pendente',
          2: 'Cancelado',
          3: 'Tratado',
        },
      },
      json_fields: {
        'Empresa': 'empresa_nome',
        'Setor': 'setor_nome',
        'Funcionário': 'funcionario',
        'Titulo': 'titulo',
        'Descrição': 'queixa',
        'Situação': {
          field: 'situacao',
          callback: (status) => {
            return this.situacao.nome[status];
          }
        },
        'Data Cadastro': {
          field: 'hora_cadastro',
          callback: (data) => {
            return data ? moment(data).format('DD/MM/YYYY') : '';
          }
        },
        'Prazo': {
          field: 'prazo',
          callback: (data) => {
            return data ? moment(data).format('DD/MM/YYYY') : '';
          }
        },
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ]
    };
  },
  methods: {
    async getRelatos () {
			console.log(this.idEmpresa)
      this.$swal({
        title: 'Preparando a Exportação'
      });
      this.$swal.showLoading()
      const result = await this.$axios.get(this.urlExportacao);
      const relatosDaEmpresa = await (result.data).filter(o => o.empresa == this.idEmpresa);
			this.$swal.close();

      return relatosDaEmpresa;
    },
  }
};
</script>
